var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.USER
    }
  }, [_c('v-select', {
    key: 'v-select-assignees' + _vm.loading,
    style: _vm.css,
    attrs: {
      "item-value": "id",
      "item-text": "firstName",
      "label": _vm.$t('project.ticket.assignees'),
      "items": _vm.users,
      "multiple": "",
      "flat": "",
      "clearable": "",
      "solo": "",
      "dense": _vm.dense,
      "outlined": _vm.outlined,
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item,
            index = _ref.index;
        return [index === 0 ? _c('v-chip', _vm._b({
          attrs: {
            "outlined": ""
          }
        }, 'v-chip', item.attrs, false), [item.firstName && item.lastName ? _c('v-avatar', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "small": "",
            "left": ""
          }
        }, [_c('small', [_vm._v(" " + _vm._s(item.firstName[0]) + _vm._s(item.lastName[0]))])]) : _vm._e(), _vm.assignees && _vm.assignees.length ? _c('small', {
          staticClass: "text-truncate",
          style: _vm.assignees.length > 1 ? 'max-width: 75px;' : 'max-widht: 100px'
        }, [_vm._v(" " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " ")]) : _vm._e()], 1) : _vm._e(), index === 1 && _vm.assignees && _vm.assignees.length ? _c('small', {
          staticClass: "grey--text "
        }, [_vm._v(" (+" + _vm._s(_vm.assignees.length - 1) + ") ")]) : _vm._e()];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item-avatar', [item.firstName && item.lastName ? _c('v-avatar', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "small": "",
            "size": "35px",
            "right": true
          }
        }, [_vm._v(" " + _vm._s(item.firstName[0]) + _vm._s(item.lastName[0]) + " ")]) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(item.userName) + " ")])], 1)];
      }
    }]),
    model: {
      value: _vm.assignees,
      callback: function callback($$v) {
        _vm.assignees = $$v;
      },
      expression: "assignees"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }